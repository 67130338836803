.accountFacility-grid {
    h1 {
        color: #003D79;
    }

    hr {
        margin-top: 3rem;
    }

}

button .svg-inline--fa {
    color: #ffffff;
}

.svg-inline--fa {
    color: #003d79;
}

#page {
    margin-left: 10px;
}

#searchFilter::-ms-clear {
    display: none;
}