.cdResponsePage table.patient-info-left {
	border: 1px solid #d1ccbf;
	height: 510px;
}

.cdResponsePage table.patient-info-left thead th {
	background: #003D79;
	color: #fff;
	padding: 8px 12px;
	height: 20px;
}

.chkboxlbl {
	margin-top: 2px;
	width: 222px;
}

.cdResponsePage table.patient-info-left td {
	border-bottom: 0;
	border-top: 0;
	/*padding:12px 12px; */
}

.cdResponsePage table.patient-info-left td:first-child {
	border-right: 1px solid #d1ccbf;
	background: #f3f2ef;
	color: #003D79;
	width: 100px;
}

.cdResponsePage #patient-tabs .tab-content table.patient-info-left thead th h3 {
	color: #fff;
	margin: 0;
	padding: 0;
	font-size: 18px;
}

.cdResponsePage table.patient-info-left-pending-response {
	border: 1px solid #d1ccbf;
	height: 250px;
}

.cdResponsePage table.patient-info-left-pending-response thead th {
	background: #003D79;
	color: #fff;
	padding: 1px 12px;
	height: 20px;
}

.cdResponsePage table.patient-info-left-pending-response td {
	border-bottom: 0;
	border-top: 0;
	/*padding:12px 12px; */
}

.cdResponsePage table.patient-info-left-pending-response td:first-child {
	border-right: 1px solid #d1ccbf;
	background: #f3f2ef;
	color: #003D79;
	width: 100px;
}

.cdResponsePage #patient-tabs .tab-content table.patient-info-left-pending-response thead th h3 {
	color: #fff;
	margin: 0;
	padding: 0;
	font-size: 18px;
}

/*TFS-34810 CSS changes for newly added collapsible Service Coverage overview */

.cdResponsePage .service-coverage {
	margin-bottom: 20px;
	clear: both;
}

.cdResponsePage .accordion-group {
	border: 0;
	border-radius: 0;
	margin-bottom: 0;
}

.cdResponsePage .service-coverage .accordion-heading .whiteText {
	color: #fff;
}

.cdResponsePage .service-coverage .accordion-heading a {
	padding: 0;
	//background: url(../../img/icon-white-uparrow.png) 99% 50% no-repeat;
}

.cdResponsePage .service-coverage .accordion-heading {
	color: #fff !important;
	font-size: 18px !important;
	margin: 0;
	padding: 8px 12px;
}

.EligibilityRequest,
.accordion-heading {
	background: #003D79;
	color: #fff;
	padding: 5px 15px;
	position: relative;
	display: block;
	border: 0;
}

.cdResponsePage .accordion-body .service-types-table {
	margin: -20px 0 0 0;
}

.cdResponsePage .service-types-table {
	width: 100%;
	margin: 20px 0 10px;
	table-layout: fixed;
	clear: both;
	border-collapse: collapse;
}

.cdResponsePage .service-types-table tr.summaryDisplaying td:first-child {
	width: 50%;
	background: #fff;
	border-right: 0;
	color: #96938e;
}

.cdResponsePage .service-types-table .summaryHideAll {
	margin: 0 0 0 15px;
}

.cdResponsePage .service-types-table .summaryShowAll {
	margin: 0 0 0 15px;
}

.cdResponsePage .service-types-table .summaryEdit {
	margin: 0 0 0 15px;
}

.cdResponsePage .service-types-table tr.summarySection {
	border-bottom: 1px solid #dfe314;
}

.cdResponsePage .service-types-table tr.summarySection td:last-child {
	border-right: 1px solid #d1ccbf;
	border-left: 0;
	background: #f2f5da;
}

.RequestHistory,
table tbody {
	border-radius: 0;
}

.RequestHistory,
table {
	width: 100%;
	padding: 0;
	margin-bottom: 15px;
	color: #555545;
	font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
	border-radius: 0;
	background: #fff;
}

.cdResponsePage .service-types-table tr.summarySection td:first-child {
	background: #f2f5da;
	border-right: 0;
}

.cdResponsePage .service-types-table tbody>tr>td:nth-child(2n + 1) {
	background-color: #f3f2ef;
	border-left: 1px solid #d1ccbf;
	border-right: 1px solid #d1ccbf;
	color: #003D79;
}

.cdResponsePage .service-types-table tr.summarySection td {
	background: #f2f5da;
}

.cdResponsePage .service-types-table tbody td:nth-child(2n + 1) {
	width: 170px;
}

.cdResponsePage .service-types-table tr:last-child td {
	border-bottom: 1px solid #d1ccbf;
}

.cdResponsePage .service-types-table tbody tr td {
	padding: 10px 20px;
	vertical-align: top;
	margin: 2px;
	border-top: 0;
}

.active {
	color: #003D79;
}

/*End*/

.cdResponsePage table.eligibility-summary.status-active thead th {
	background: #003D79;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 1px 12px;
}

.cdResponsePage table.eligibility-summary.status-active tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #f2f5da;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary.status-inactive thead th {
	background: #933311;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 1px 12px;
}

.cdResponsePage table.eligibility-summary.status-inactive tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #efe1dc;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary.status-missing thead th {
	background: #c66005;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 10px 12px;
}

.cdResponsePage table.eligibility-summary.status-missing tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #f4dfcd;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary.status-failed thead th {
	background: #5f6062;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 10px 12px;
}

.cdResponsePage table.eligibility-summary.status-failed tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #f3f2ef;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary.status-pending thead th {
	background: #003D79;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 10px 12px;
}

.cdResponsePage table.eligibility-summary.status-pending tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #eae3f5;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary.status-alert thead th {
	background: #deb406;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 1px 12px;
}

.cdResponsePage table.eligibility-summary.status-alert tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #f0e8c8;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary.status-nonedi thead th {
	background: #4d917b;
	color: #fff;
	font-size: 17px;
	font-weight: 200;
	padding: 10px 12px;
}

.cdResponsePage table.eligibility-summary.status-nonedi tbody td {
	padding: 15px 12px;
	border-right: 1px solid #d1ccbf;
	background: #eae3f5;
	vertical-align: top;
}

.cdResponsePage table.eligibility-summary tbody {
	border-left: 1px solid #d1ccbf;
	border-right: 1px solid #d1ccbf;
	border-bottom: 1px solid #d1ccbf;
}

.cdResponsePage .eligibility-summary .eligibility-summary-subcontent {
	margin: 0;
	border: 0;
}

.cdResponsePage .eligibility-summary .eligibility-summary-subcontent td {
	margin: 0;
	border: 0;
}

.cdResponsePage table.eligibility-summary tbody td:nth-child(2n + 1) {
	color: #003D79;
	width: 130px;
}

.bottom-border-td {
	border-bottom: 1px solid #d1ccbf;
}

.cdResponsePage .service-types-table tr.sub-header {
	color: #003D79;
	font-size: 15px;
	font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 200;
	border-bottom: 1px solid #d1ccbf;
	background: #eae3f5;
}

.cdResponsePage .service-types-table tr.sub-header td {
	background: #eae3f5;
}

.cdResponsePage .service-types-table tr.sub-header td:nth-child(2n + 1) {
	background: #eae3f5;
}

.cdResponsePage .service-types-table tr.topline,
.el3ResponsePage .service-types-table td.topline {
	border-top: 1px solid #d1ccbf;
}

/*TFS-34810 CSS changes for newly added collapsible Service Coverage overview */
.cdResponsePage .service-coverage .accordion-heading {
	color: #ffffff !important;
	font-size: 18px !important;
	margin: 0;
	padding: 8px 12px;
}

.cdResponsePage .service-coverage .accordion-heading a.collapsed {
	padding: 0;
	background: url('../../../../img/icon-white-downarrow.png') 99% 50% no-repeat;
}

.cdResponsePage .service-coverage .accordion-heading a {
	padding: 0;
	background: url('../../../../img/icon-white-up-arrow-big.png') 99% 50% no-repeat;
}

.cdResponsePage .service-coverage .accordion-heading .whiteText {
	color: #ffffff;
}

.cdResponsePage .accordion-body .service-types-table {
	margin: -20px 0px 0px 0px;
}

.cdResponsePage .service-coverage {
	margin-bottom: 20px;
	clear: both;
}

/*End*/

.cdResponsePage .service-types-table tr.summarySection.noCoverage td {
	background: #efe1dc;
}

.cdResponsePage .service-types-table tr.summarySection.noCoverage td:first-child {
	background: #efe1dc;
	border-right: 0px !important;
}

.cdResponsePage .service-types-table tr.summarySection.noCoverage td:nth-child(2) {
	background: #efe1dc;
	background-color: #f2dede;
}

.cdResponsePage .service-types-table tr.summarySection.noCoverage td:last-child {
	background: #efe1dc;
}

.cdResponsePage .service-types-table td.alert.alert-error {
	padding-top: 20px;
	border-right: 0px !important;
	border-left: 0px !important;
	border-bottom: 1px solid #d1ccbf;
}

.cdResponsePage .service-types-table .summarySection td.active {
	padding-top: 20px;
	border-right: 0px !important;
}

.cdResponsePage .service-types-table .summarySection td:first-child {
	border-right: 0px !important;
}

.eligibility-summary-title {
	float: left;
	color: #ffffff !important;
}