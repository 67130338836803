#Active {
    width: 84px !important;
    margin-top: 6px;
    margin-bottom: 26px;
}

.editNpi .UserFacility-grid thead:first-child tr:first-child th {
    background: #003D79;
    font-size: 12px;
}

.editNpi .UserFacility-grid tr td {
    padding-left: 100px !important;
}


.editNpi .UserFacility-grid thead:first-child tr:first-child th:hover {
    text-decoration: underline !important;
    cursor: pointer;
}


#showPageSize {
    margin-left: 11px;
    margin-top: -3px;
}

#showPagination {
    margin-top: -8px;
}


#showPageSizeLower {
    margin-left: 11px;
    margin-top: 11px;
}

#showPaginationLower {
    margin-top: 11px;
}


#facilityButtons {
    margin-left: -33%;
}


#page {
    margin-left: 10px;
}

#showPage {
    margin-top: 15px;
}

#marginLeft {
    margin-left: 10px;
}

#marginRight {
    margin-right: 10px;
}

.form-control.is-valid {
    margin-bottom: 10px !important;
}