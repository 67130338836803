.eligibility-summary-title {
	float: left;
	color: #ffffff !important;
}

.eligibility-summary-title-image{
    float: right; 
    padding-right: 10px;
    padding-top: 8px;
    width: 30px
}