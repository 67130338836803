@import '../../node_modules/bootstrap-scss/bootstrap.scss';
@import '../../node_modules/bootstrap-scss/bootstrap-grid.scss';

/* Colors */

$green: #003D79;
$black: #2d2926;
$gray: #54585a;
$lightGray: #898d8d;
$darkBlue: #003d79;
$lightBlue: #00afd7;
$orange: #ffb600;
$inovGray: #c7c9c7;
$inovLightGray: #ebeceb;
$border: #ced4da;
$lightBg: #f5f5f5;
$border-color: #ced4da;
$light-border-color: #e5e6dd;
$sm-radius: 5px 0;
$md-radius: 10px 0;
$lg-radius: 15px 0;
$xl-radius: 20px 0;

$control-invalid-border-color: #dc3545;
$control-invalid-background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");

$control-padding-right : calc(1.5em + 0.75rem);
$control-background-position: right calc(0.375em + 1.1875rem) center !important;
$control-background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
$control-background-repeat: no-repeat;

$control-valid-border-color: #28a745;
$control-valid-background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");

i.icon-purple-left-arrow {
	background: url("../img/purple-left-caret.png") no-repeat;
	width: 5px;
	height: 8px;
	margin: 8px 0 0 5px;
	padding-right: 4px;
}

/* make the page width consistent with the MYA site centered and fixed width */

.container {
	width: 990px;
	position: relative;
	clear: both;
}

/* ensure the myABILITY ribbon in the header has the proper sizing */

.navbar {
	padding: 0px;
}

/* this is a carry-over from myABILITY core portal header using bootstrap v2/v3 */

.pull-right {
	float: right;
}

/* compatibility from myability portal bootstrap v2 where span is still used */

[class*='span'] {
	margin-left: 20px;
	float: left;
	min-height: 1px;
}

/* compatibility with myability header between logo on the top right and links on the left */

.row {
	display: flex;
	flex-wrap: nowrap !important;
}

/* compatibility with myability menu items */

*,
::after,
::before {
	box-sizing: initial !important;
}

/*
   modal dialogs in myABILITY have a 645px fixed width. for some reason bootstrap 4 dialogs come across misaligned
   when mixed with the core portal css. the following rule overrides the core portal modal positioning and ensures
   the "left" positioning is 50% of the screen minus half the 645px width of the modal
*/

.modal-backdrop {
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	position: fixed;
	z-index: 1040;
	background-color: black;
}

.fade.modal-backdrop {
	opacity: 0;
}

.modal-backdrop {
	opacity: 0.8;
}

.in.fade.modal-backdrop {
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.modal {
	outline: 0px;
	border-radius: 6px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	left: 50%;
	top: 10%;
	width: 560px;
	margin-left: -280px;
	position: fixed;
	z-index: 1050;
	box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.3);
	background-clip: padding-box;
	background-color: white;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding-box;
}

.fade.modal {
	transition: opacity 0.3s linear, top 0.3s ease-out;
	//  top: -25%;
	-webkit-transition: opacity .3s linear, top .3s ease-out;
	-moz-transition: opacity .3s linear, top .3s ease-out;
	-o-transition: opacity .3s linear, top .3s ease-out;
}

.in.fade.modal {
	top: 10%;
}

.modal-header {
	padding: 9px 15px;
	border-bottom-color: #eeeeee;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.modal-header .close {
	margin-top: 2px;
}

.modal-header h3 {
	margin: 0px;
	line-height: 30px;
}

.modal-body {
	padding: 15px;
	position: relative;
	-ms-overflow-y: auto;
	max-height: 400px;
}

.modal-form {
	margin-bottom: 0px;
}

.modal-footer {
	padding: 14px 15px 15px;
	border-radius: 0px 0px 6px 6px;
	text-align: right;
	margin-bottom: 0px;
	border-top-color: #dddddd;
	border-top-width: 1px;
	border-top-style: solid;
	box-shadow: inset 0px 1px 0px #fff;
	background-color: #f5f5f5;
	-webkit-border-radius: 0 0 6px 6px;
	-moz-border-radius: 0 0 6px 6px;
	-webkit-box-shadow: inset 0 1px 0 #fff;
	-moz-box-shadow: inset 0 1px 0 #fff;
}

.modal-footer::before {
	line-height: 0;
	display: table;
	content: "";
}

.modal-footer::after {
	line-height: 0;
	display: table;
	content: "";
}

.modal-footer::after {
	clear: both;
}

.modal-footer .btn+.btn {
	margin-bottom: 0px;
	margin-left: 5px;
}

.modal-footer .btn-group .btn+.btn {
	margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
	margin-left: 0px;
}

/*
	dropdown items in the items grid (for selecting paging size) have an invalid padding setting.
	this rule corrects the padding.
*/

.dropdown-item {
	padding: 0px;
}

/*
.form-control {
    width: 100%
}
*/

#manageLinkModal .btn {
	border-width: 1px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #b3b3b3;
	padding: 4px 12px;
	border-radius: 4px;
	text-align: center;
	color: #333333;
	line-height: 20px;
	font-size: 14px;
	margin-bottom: 0px;
	vertical-align: middle;
	display: inline-block;
	cursor: pointer;
	box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.05);
	text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.75);
	background-image: linear-gradient(white, #e6e6e6);
	background-repeat: repeat-x;
	background-color: #f5f5f5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

#manageLinkModal .btn:active {
	color: #333333;
	background-color: #e6e6e6;
}

#manageLinkModal .btn:hover {
	background-position: 0px -15px;
	transition: background-position 0.1s linear;
	color: #333333;
	text-decoration: none;
	-webkit-transition: background-position .1s linear;
	-moz-transition: background-position .1s linear;
	-o-transition: background-position .1s linear;
}

#manageLinkModal .btn:focus {
	background-position: 0px -15px;
	transition: background-position 0.1s linear;
	color: #333333;
	text-decoration: none;
	-webkit-transition: background-position .1s linear;
	-moz-transition: background-position .1s linear;
	-o-transition: background-position .1s linear;
}

#manageLinkModal {
	width: 850px;
	left: 39%;
}

#manageLinkModal #btnUp,
#manageLinkModal #btnDown {
	width: auto;
	float: right;
	margin-top: 15px;
}

.modal h3 {
	font-size: 30px;
	line-height: 36px;
	color: #c66005;
	font-weight: 200;
	padding: 0 20px 0 0;
}

#manageLinkModal .row-fluid [class*='span'] {
	width: 100%;
	margin-left: 2.12%;
	float: left;
	display: block;
	min-height: 30px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

#manageLinkModal .row-fluid .span12 {
	width: 100%;
}

#manageLinkModal #manageLink {
	margin-bottom: 0px;
}

#manageLinkModal .btn.save {
	width: 100%;
	padding: 8px 15px 8px 10px;
	background: #003D79;
	color: #ffffff !important;
	font-size: 16px;
	border-radius: 0;
	border: 0;
	text-shadow: none;
}

#manageLinkModal .btn.cancel {
	width: 100%;
	padding: 8px 15px;
	background: #96938e;
	color: #ffffff !important;
	font-size: 16px;
	border-radius: 0;
	border: 0;
	text-shadow: none;
	z-index: 1050;
}

.modal form {
	padding: 0 20px;
}

form {
	margin: 0px 0px 10px;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-secondary .caret {
	color: #003D79;
	margin-left: 10px;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

/*.btn.dropdown-toggle {
  padding: 6px 10px 6px 10px;
}*/
.dropdown-toggle::after {
	display: none;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	/* content: ""; */
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.btn-default .caret {
	color: $green;
	margin-left: 10px;
}

.btn-primary {
	color: #fff !important;
	background-color: $green;
	border-color: $green;

	&:hover {
		color: #fff;
		background-color: lighten($green, 5%);
		border-color: lighten($green, 5%);
		background: lighten($green, 5%) radial-gradient(circle, transparent 1%, lighten($green, 5%) 1%) center/15000%;
	}

	&:active {
		color: #fff;
		background-color: lighten($green, 15%);
		background-size: 100%;
		border-color: lighten($green, 15%);
		transition: background 0s;
	}

	&:focus {
		color: #fff;
	}

	&:dropdown-toggle {
		color: #fff !important;
		background-color: $green;
		border-color: $green;
	}
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	color: $black;
	background-color: $green;
	border-color: $green;
}

.page-link {
	color: $green;
	border-color: $green;
}

.page-item.active .page-link {
	color: #fff !important;
	background-color: $green;
	border-color: $green;
}

.btn-secondary {
	color: $gray !important;
	background: none !important;
	border-color: #ccc;

	&.selected {
		background-color: $green;
		color: white;

		&:hover {
			background-color: $green;
			color: white;
		}
	}

	&:hover {
		background-color: #f1f1f1;
		border-color: #f1f1f1;
		background: darken(#f1f1f1, 5%) radial-gradient(circle, transparent 1%, darken(#f1f1f1, 5%) 1%) center/15000%;
	}

	&:active {
		color: $gray;
		background-color: lighten(#f1f1f1, 15%);
		background-size: 100%;
		border-color: lighten(#f1f1f1, 15%);
		transition: background 0s;
	}
}

.btn-danger {
	color: $white !important;
	background-color: #f00;
	border-color: #333;

	&.selected {
		background-color: $green;
		color: white;

		&:hover {
			background-color: $green;
			color: white;
		}
	}

	&:hover {
		background-color: #f1f1f1;
		border-color: #f1f1f1;
		background: darken(#f1f1f1, 5%) radial-gradient(circle, transparent 1%, darken(#f1f1f1, 5%) 1%) center/15000%;
	}

	&:active {
		color: $gray;
		background-color: lighten(#f1f1f1, 15%);
		background-size: 100%;
		border-color: lighten(#f1f1f1, 15%);
		transition: background 0s;
	}
}

.btn-dataTable-toggle {
	color: $gray;
	background-color: #fff;
	border-color: #ccc;

	&:hover {
		background-color: #f1f1f1;
		border-color: #f1f1f1;
		background: darken(#f1f1f1, 5%) radial-gradient(circle, transparent 1%, darken(#f1f1f1, 5%) 1%) center/15000%;
	}

	&:active {
		color: $gray;
		background-color: lighten(#f1f1f1, 15%);
		background-size: 100%;
		border-color: lighten(#f1f1f1, 15%);
		transition: background 0s;
	}
}

.btn-other {
	color: #fff !important;
	background-color: $black !important;
	border-color: $black;

	&:hover {
		color: #fff;
		background-color: lighten($black, 10%);
		border-color: lighten($black, 10%);
		background: lighten($black, 10%) radial-gradient(circle, transparent 1%, lighten($black, 10%) 1%) center/15000%;
	}

	&:active {
		color: #fff;
		background-color: lighten($black, 25%);
		background-size: 100%;
		border-color: lighten($black, 25%);
		transition: background 0s;
	}

	&:focus {
		color: #fff;
	}
}

.btn-reset {
	color: #fff;
	background-color: $lightGray;
	border-color: $lightGray;

	&:hover {
		color: #fff;
		background-color: lighten($lightGray, 10%);
		border-color: lighten($lightGray, 10%);
		background: lighten($lightGray, 10%) radial-gradient(circle, transparent 1%, lighten($lightGray, 10%) 1%) center/15000%;
	}

	&:active {
		color: #fff;
		background-color: lighten($lightGray, 25%);
		background-size: 100%;
		border-color: lighten($lightGray, 25%);
		transition: background 0s;
	}

	&:focus {
		color: #fff;
	}
}

.btn-cancel {
	color: #fff;
	background-color: $gray;
	border-color: $gray;

	&:hover {
		color: #fff;
		background-color: lighten($gray, 10%);
		border-color: lighten($gray, 10%);
		background: lighten($gray, 10%) radial-gradient(circle, transparent 1%, lighten($gray, 10%) 1%) center/15000%;
	}

	&:active {
		color: #fff;
		background-color: lighten($gray, 25%);
		background-size: 100%;
		border-color: lighten($gray, 25%);
		transition: background 0s;
	}

	&:focus {
		color: #fff;
	}
}

.btn-warning {
	color: $black;
	background-color: $orange;
	border-color: $orange;

	&:hover {
		color: $black;
		background-color: lighten($orange, 10%);
		border-color: lighten($orange, 10%);
		background: lighten($orange, 10%) radial-gradient(circle, transparent 1%, lighten($orange, 10%) 1%) center/15000%;
	}

	&:active {
		color: $black;
		background-color: lighten($orange, 25%);
		background-size: 100%;
		border-color: lighten($orange, 25%);
		transition: background 0s;
	}

	&:focus {
		color: $black;
		border-color: lighten($orange, 25%);
	}
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.open>.dropdown-menu {
	display: block;
}

.dropdown-menu>li>a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap;
}

.dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.btn-group>.btn+.dropdown-toggle {
	padding-right: 8px;
	padding-left: 8px;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
	outline: 0;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
	margin-left: -1px;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover,
.btn-group>.btn:focus,
.btn-group-vertical>.btn:focus,
.btn-group>.btn:active,
.btn-group-vertical>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn.active {
	z-index: 2;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	float: left;
}

.btn .caret {
	margin-left: 0;
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0;
}

.btn-group-justified {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
}

.btn-group-justified>.btn-group .btn {
	width: 100%;
}

.btn-group>.btn:first-child {
	margin-left: 0;
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
	border-radius: 0;
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
	display: table-cell;
	float: none;
	width: 1%;
}

.btn-group .btn-secondary:hover,
.btn-group .btn-dataTable-toggle:hover {
	border-color: $border-color;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group .form-control:first-child {
	border-right: none;
}

h1 {
	margin: 10px 0;
	font-size: 32px;
	color: $lightGray;
	font-family: "Muli", arial, sans-serif;
}

h2 {
	margin: 0;
	font-size: 24px;
	color: $lightGray;
	font-family: "Muli", arial, sans-serif;
	line-height: 24px;
}

h3 {
	font-size: 20px;
	color: $gray;
	font-family: "Muli", arial, sans-serif;
}

h4 {
	font-size: 18px;
	color: $lightGray;
	font-family: "Muli", arial, sans-serif;
}

a {
	text-decoration: none;
}

p {
	margin: 10px 0;
	line-height: 1.4;
}

.largeHighlight {
	font-size: 42px;
}

table.dataTable {
	width: 100%;
	margin: 0 auto;
	clear: both;
	border-collapse: separate;
	border-spacing: 0;
	border: 1px solid $border-color;
}

table.dataTable thead th,
table.dataTable tfoot th {
	font-weight: 600;
	color: #003D79;
}

table.dataTable thead th,
table.dataTable thead td {
	padding: 12px 10px;
	border-bottom: 2px solid #ced4da;
	text-align: left;
}

table.dataTable thead th:active,
table.dataTable thead td:active {
	outline: none;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
	padding: 10px 18px 6px 18px;
	border-top: 1px solid #111;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	*cursor: hand;
	background-repeat: no-repeat;
	background-position: center right;
}

table.dataTable thead .sorting::after {
	content: "\f362";
	transform: rotate(90deg);
	color: #c7c9c7;
}

table.dataTable thead .sorting_asc::after {
	content: "\f160";
	color: #333;
}

table.dataTable thead .sorting_desc::after {
	content: "\f161";
	color: #333;
}

table.dataTable thead .sorting_asc_disabled::after {
	content: "\f160";
	color: #898d8d;
}

table.dataTable thead .sorting_desc_disabled::after {
	content: "\f161";
	color: #898d8d;
}

table.dataTable tbody tr {
	background-color: #ffffff;
}

table.dataTable tbody tr.selected {
	background-color: #e1efb6;
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 8px 10px;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
	border-top: 1px solid #ddd;
}

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
	border-top: none;
}

table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
	border-top: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
	border-left: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
	border-top: none;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
	background-color: #f9f9f9;
}

table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
	background-color: #e1efb6;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
	background-color: #f6f6f6;
}

table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
	background-color: #aab7d1;
}

table.dataTable.order-column tbody tr>.sorting_1,
table.dataTable.order-column tbody tr>.sorting_2,
table.dataTable.order-column tbody tr>.sorting_3,
table.dataTable.display tbody tr>.sorting_1,
table.dataTable.display tbody tr>.sorting_2,
table.dataTable.display tbody tr>.sorting_3 {
	background-color: #fafafa;
}

table.dataTable.order-column tbody tr.selected>.sorting_1,
table.dataTable.order-column tbody tr.selected>.sorting_2,
table.dataTable.order-column tbody tr.selected>.sorting_3,
table.dataTable.display tbody tr.selected>.sorting_1,
table.dataTable.display tbody tr.selected>.sorting_2,
table.dataTable.display tbody tr.selected>.sorting_3 {
	background-color: #acbad5;
}

table.dataTable.display tbody tr.odd>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
	background-color: #f1f1f1;
}

table.dataTable.display tbody tr.odd>.sorting_2,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_2 {
	background-color: #f3f3f3;
}

table.dataTable.display tbody tr.odd>.sorting_3,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_3 {
	background-color: whitesmoke;
}

table.dataTable.display tbody tr.odd.selected>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
	background-color: #a6b4cd;
}

table.dataTable.display tbody tr.odd.selected>.sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_2 {
	background-color: #a8b5cf;
}

table.dataTable.display tbody tr.odd.selected>.sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_3 {
	background-color: #a9b7d1;
}

table.dataTable.display tbody tr.even>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
	background-color: #fafafa;
}

table.dataTable.display tbody tr.even>.sorting_2,
table.dataTable.order-column.stripe tbody tr.even>.sorting_2 {
	background-color: #fcfcfc;
}

table.dataTable.display tbody tr.even>.sorting_3,
table.dataTable.order-column.stripe tbody tr.even>.sorting_3 {
	background-color: #fefefe;
}

table.dataTable.display tbody tr.even.selected>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1 {
	background-color: #acbad5;
}

table.dataTable.display tbody tr.even.selected>.sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_2 {
	background-color: #aebcd6;
}

table.dataTable.display tbody tr.even.selected>.sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_3 {
	background-color: #afbdd8;
}

table.dataTable.display tbody tr:hover>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
	background-color: #eaeaea;
}

table.dataTable.display tbody tr:hover>.sorting_2,
table.dataTable.order-column.hover tbody tr:hover>.sorting_2 {
	background-color: #ececec;
}

table.dataTable.display tbody tr:hover>.sorting_3,
table.dataTable.order-column.hover tbody tr:hover>.sorting_3 {
	background-color: #efefef;
}

table.dataTable.display tbody tr:hover.selected>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
	background-color: #a2aec7;
}

table.dataTable.display tbody tr:hover.selected>.sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_2 {
	background-color: #a3b0c9;
}

table.dataTable.display tbody tr:hover.selected>.sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_3 {
	background-color: #a5b2cb;
}

table.dataTable.no-footer {
	/*border-bottom: 1px solid #111;*/
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: nowrap;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
	padding: 4px 17px 4px 4px;
}

table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
	padding: 4px;
}

table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
	padding: 4px;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
	text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
	text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
	text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
	text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
	white-space: nowrap;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
	text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
	text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
	text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
	text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
	white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
	text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
	text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
	text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
	text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
	white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
	box-sizing: content-box;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
	position: relative;
	clear: both;
	*zoom: 1;
	zoom: 1;
}

.dataTables_wrapper .dataTables_length {
	float: left;
}

.dataTables_wrapper .dataTables_filter {
	float: right;
	text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
	margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
	/*clear: both;*/
	float: left;
	padding-top: 6px;
	margin-left: 20%;
}

.dataTables_wrapper .dataTables_paginate {
	float: right;
	text-align: right;
	padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	position: relative;
	padding: 8px 12px;
	margin-left: -1px;
	line-height: 25px;
	color: #003D79;
	text-decoration: none;
	border: 1px solid #ced4da;
	border-top: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: #003D79;
	font-weight: bold;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #bebebe !important;
	border: 0 1px 1px 1px solid #ced4da;
	background: transparent;
	box-shadow: none;
	cursor: not-allowed;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	z-index: 2;
	color: #003D79;
	background-color: #eee;
	border-color: #ddd;
	cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	z-index: 2;
	color: #003D79;
	background-color: #eee;
	border-color: #ddd;
	cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em;
}

.dataTables_wrapper .dataTables_processing {
	z-index: 9999;
	position: absolute;
	top: 0px;
	left: 50%;
	width: 100%;
	height: 100%;
	margin-left: -50%;
	text-align: center;
	font-size: 1.2em;
	background-color: rgba(255, 255, 255, 0.7);
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: inherit;
}

.dataTables_wrapper .dataTables_scroll {
	clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
	vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td>div.dataTables_sizing {
	height: 0;
	overflow: hidden;
	margin: 0 !important;
	padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: 1px solid #111;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody>table {
	border-bottom: none;
}

.dataTables_wrapper:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

@media screen and (max-width: 767px) {

	.dataTables_wrapper .dataTables_info,
	.dataTables_wrapper .dataTables_paginate {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_paginate {
		margin-top: 0.5em;
	}
}

@media screen and (max-width: 640px) {

	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_filter {
		margin-top: 0.5em;
	}
}

/* Custom Styles */
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
	position: absolute;
	bottom: 13px;
	right: 8px;
	display: block;
	font-family: "Font Awesome 5 Free";
	font-size: 12px;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	position: relative;
}

div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 0;
}

.dataTables_wrapper .dataTables_filter {
	float: left;
	text-align: left;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_paginate {
	float: right;
}

.dataTables_wrapper label {
	font-weight: 400;
}

div.dataTables_filter input,
.dataTables_length select {
	border: 1px solid #ced4da;
	height: 34px;
	padding: 6px 12px;
	padding: 0.25rem 0.5rem;
	line-height: 1.5;
	border-radius: 0.2rem;
	-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	width: auto !important;
	margin-bottom: 0 !important;
}

div.dataTables_filter input {
	height: auto;
	padding: 6px 5px;
}

div.dataTables_filter input:focus,
.dataTables_length select:focus {
	border: 1px solid $green;
	outline: none;
	box-shadow: none;
}

.dataTables_wrapper .dataTables_length {
	float: left;
}

.dataTables_wrapper .dataTables_paginate {
	margin-top: 1px !important;
	float: right;
}

.dataTables_wrapper .dataTable tr td i {
	font-size: 18px;
	margin-right: 8px;
	color: #898d8d;
	cursor: pointer;

	&:hover {
		color: $green;
	}
}

td.details-control {
	text-align: center;
	font-size: 16px;
	cursor: pointer;

	i.fa-minus-circle {
		color: $gray;
	}
}

td.arSummary-details-control {
	text-align: left;
	cursor: pointer;
	font-size: 16px;
	color: $green;
	font-weight: 600;

	i {
		margin-right: 10px;
		color: $lightGray;
		font-size: 16px !important;

		&.fa-minus-square {
			color: $green;

			&.hidden {
				visibility: hidden;
			}
		}

		&.fa-plus-square {
			&.hidden {
				visibility: hidden;
			}
		}
	}
}

table.dataTable .subData {
	th {
		background: $inovLightGray;
		color: $gray !important;
		border-bottom: none;
		padding: 6px 5px;
	}

	td {
		padding: 8px 5px;
	}

	td i,
	td a i {
		margin-right: 5px;
		font-size: 16px;
		color: $lightGray;
		cursor: pointer;

		&:hover {
			color: $green;
		}
	}
}

.container {
	width: 990px;
	margin: 0 auto;
}

a {
	color: $green;
	cursor: pointer;
	text-decoration: none;
}

/*
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*/
select {
	font-family: "Muli", arial, sans-serif !important;
}

a .card:hover {
	border: 1px solid $green;
}

.card {
	width: 100%;
	border: 1px solid $light-border-color;
	border-radius: $md-radius;
	padding: 15px 10px;
	transition: all .3s ease-in-out;
	color: $lightGray;

	&.dashboardCard {
		/*height: 112px;*/
		display: table;
		padding: 5px 10px;
		/*font-size: 13px;*/
	}

	a {
		color: $lightGray;
	}

	&.statusCountCard {
		padding: 6px;

		h3 {
			margin: 5px 0 0 0;
			font-size: 17px;
		}
	}
}

.form-control.dashboardMonthSelector {
	font-size: 20px;
	color: $lightGray;
	border-top: none;
	border-left: none;
	border-right: none;
	height: auto;
	padding: 0px 3px;
	border-radius: 0;
	vertical-align: baseline;
}

.alert {
	border: 1px solid transparent;
	padding: 8px 10px;
	position: relative;
	border-radius: $sm-radius;
	color: #c09853 !important;

	.close {
		position: absolute;
		color: #cecece;
		right: -21px !important;
	}

	.close:hover {
		color: #000;
		text-decoration: none;
		cursor: pointer;
		opacity: .4 !important;
		line-height: 20px;
		position: relative;
	}

	.alert-dismissible {
		padding-right: 35px;
	}
}

.alert-success {
	color: $gray;
	background-color: #fff;
	border-color: $green;
}

.alert-message {
	color: $gray;
	background-color: #f3f3f3;
	border-color: #d6d8db;
}

.alert-error {
	background-color: #f2dede;
	border-color: #ce6a6a;
	color: #ba4a4a;
}

.alert-warning {
	background-color: lighten($orange, 42%);
	border-color: darken($orange, 5%);
	color: darken($orange, 25%);
}

.close:not(:disabled):not(.disabled) {
	cursor: pointer;
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
}

label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: bold;
}

.errorBar {
	background: #e87373;
	padding: 15px 0;
	text-align: center;
	color: white;
	position: fixed;
	bottom: 0;
	width: 100%;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group .form-control:first-child {
	border-right: none;
}

/* Shadows */
.box-shadow {
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

/* Text */
.semibold {
	font-weight: 700;
}

.bold {
	font-weight: 900;
}

.text-center {
	text-align: center;
}

.subtext {
	font-size: 13px;
}

/* Positioning */
.float-right {
	float: right;
}

.float-left {
	float: left;
}

.iblock {
	display: inline-block !important;
}

.vmiddle {
	vertical-align: middle;
}

.t-cell {
	display: table-cell;
}

.center-center {
	vertical-align: middle;
	display: table-cell;
}

.w-auto {
	width: auto !important;
}

/* Margins */
.mr0 {
	margin-right: 0 !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mr10 {
	margin-right: 10px;
}

.mr15 {
	margin-right: 15px;
}

.mr20 {
	margin-right: 20px;
}

.ml0 {
	margin-left: 0 !important;
}

.ml5 {
	margin-left: 5px;
}

.ml10 {
	margin-left: 10px;
}

.ml15 {
	margin-left: 15px;
}

.ml20 {
	margin-left: 20px;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb5 {
	margin-bottom: 5px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb40 {
	margin-bottom: 40px;
}

.mt0 {
	margin-top: 0;
}

.mt5 {
	margin-top: 5px;
}

.mt8 {
	margin-top: 8px;
}

.mt10 {
	margin-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mt20 {
	margin-top: 20px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}

/* Padding */
.pl10 {
	padding-left: 10px;
}

/* Colors */
.green,
.paid,
.billed {
	color: $green !important;
}

.fulfilled,
.blue {
	color: $darkBlue;
}

.unfulfilled,
.orange {
	color: $orange;
}

.gray {
	color: $gray;
}

.lightGray {
	color: $lightGray;
}

.red,
.error,
.rejected {
	color: red;
}

.matchFormHeight {
	height: 34px;
}

.no-border {
	border: none;
}

i.disabled {
	color: #d7d7d6 !important;
}

/* Animation */
/*.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

.fade.show {
    opacity: 1;
}*/
/* Styles */
.form-control:focus {
	outline: $green auto 5px;
}

:focus {
	outline: none;
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
	clear: both;
}

.form-group::after,
.form-group::before {
	clear: both;
	display: table;
	content: " ";
}

/* Layout */
.clearfix::after {
	content: "";
	clear: both;
	display: table;
}

.invisible {
	visibility: hidden;
}

.hide {
	display: none;
}

/* Tooltip */
.tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.42857143;
	line-break: auto;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	white-space: normal;
	font-size: 12px;
	//filter: alpha(opacity=0);
	opacity: 0;
}

.tooltip.in {
	filter: alpha(opacity=90);
	opacity: 0.9;
}

.tooltip.top {
	padding: 5px 0;
	margin-top: -3px;
}

.tooltip.right {
	padding: 0 5px;
	margin-left: 3px;
}

.tooltip.bottom {
	padding: 5px 0;
	margin-top: 3px;
}

.tooltip.left {
	padding: 0 5px;
	margin-left: -3px;
}

.tooltip.top .tooltip-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000000;
}

.tooltip.top-left .tooltip-arrow {
	right: 5px;
	bottom: 0;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000000;
}

.tooltip.top-right .tooltip-arrow {
	bottom: 0;
	left: 5px;
	margin-bottom: -5px;
	border-width: 5px 5px 0;
	border-top-color: #000000;
}

.tooltip.right .tooltip-arrow {
	top: 50%;
	left: 0;
	margin-top: -5px;
	border-width: 5px 5px 5px 0;
	border-right-color: #000000;
}

.tooltip.left .tooltip-arrow {
	top: 50%;
	right: 0;
	margin-top: -5px;
	border-width: 5px 0 5px 5px;
	border-left-color: #000000;
}

.tooltip.bottom .tooltip-arrow {
	top: 0;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000000;
}

.tooltip.bottom-left .tooltip-arrow {
	top: 0;
	right: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000000;
}

.tooltip.bottom-right .tooltip-arrow {
	top: 0;
	left: 5px;
	margin-top: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #000000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #ffffff;
	text-align: center;
	background-color: #000000;
	border-radius: 4px;
}

.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

.truncate {
	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

td.serviceStatus .tooltip {
	opacity: 1;

	.tooltip-inner {
		background-color: white !important;
		font-size: 14px;
		opacity: 1 !important;
		border: 1px solid $orange;
		color: $orange;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-right-color: $gray !important;
	}
}

.all-rounded {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.progress-bar {
	width: 300px;
	background: #d9d9d9;
	position: absolute;
}

.progress-bar-percentage {
	background: #003D79;
	color: #555;
	text-align: center;
	height: 17px;
}

.progress-bar-percentage span {
	display: inherit;
	position: absolute;
	width: 100%;
	font-weight: 600;
	height: inherit;
}

.datatableRunDate {
	white-space: nowrap;
}

a.lnkShowAllCodes[disabled],
a.lnkHideAllCodes[disabled] {
	color: grey;
	text-decoration: none;
}

a {
	color: #003D79;
	text-decoration: none;
	outline: 0;
}

a:focus {
	outline: 0;
}

a:hover {
	color: #205589;
	text-decoration: underline;
	outline: 0;
}

.row-fluid {
	width: 100%;
	position: relative;
}

.bottom-border-td {
	border-bottom: 1px solid #d1ccbf;
}

.container {
	width: 990px;
	position: relative;
	clear: both;
}

.navbar-static-top .container {
	width: 990px;
}

.navbar-fixed-top .container {
	width: 990px;
}

.navbar-fixed-bottom .container {
	width: 990px;
}

.span12 {
	width: 990px;
}

.span11 {
	width: 875px;
}

.span10 {
	width: 795px;
}

.span9 {
	width: 715px;
}

.span8 {
	width: 635px;
}

.span7 {
	width: 555px;
}

.span6 {
	width: 475px;
}

.span5 {
	width: 395px;
}

.span4 {
	width: 295px;
}

.span3 {
	width: 235px;
}

.span2 {
	width: 155px;
}

.span1 {
	width: 75px;
}

.required1 sup {
	font-weight: bold;
	position: relative;
	padding-left: 10px;
	font-size: 15px;
}

h1 {
	margin: 10px 0px;
	color: inherit;
	line-height: 22px;
	text-rendering: optimizelegibility;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 200;
}

h2 {
	margin: 10px 0px;
	color: inherit;
	line-height: 35px;
	text-rendering: optimizelegibility;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 200;
}

h3 {
	margin: 10px 0px;
	color: inherit;
	line-height: 20px;
	text-rendering: optimizelegibility;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 200;
}

h4 {
	margin: 10px 0px;
	color: inherit;
	line-height: 20px;
	text-rendering: optimizelegibility;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 200;
}

h5 {
	margin: 10px 0px;
	color: inherit;
	line-height: 20px;
	text-rendering: optimizelegibility;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 200;
}

h6 {
	margin: 10px 0px;
	color: inherit;
	line-height: 20px;
	text-rendering: optimizelegibility;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 200;
}

p {
	margin: 0px 0px 10px;
	font-size: 14px;
}

form {
	margin: 0px 0px 10px;
}

.ZeroHeight {
	margin-bottom: 0px !important;
}

.buttonWidth80 {
	width: 80px !important;
}

.buttonWidth100 {
	width: 100px !important;
}

/*BUG:24042- Fixed Save Button on User Management update is deformed in FF*/
.buttonWidth110 {
	width: 110px !important;
}

.npiSelect .row-fluid {
	margin-left: -10px;
	padding-right: 10px;
}

.span9.search {
	margin-left: 2.12% !important;
}

select {
	padding: 4px 6px;
	border-radius: 0;
	height: 20px;
	color: #555555;
	line-height: 20px;
	font-size: 14px;
	margin-bottom: 10px;
	vertical-align: middle;
	display: inline-block;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	behavior: url(/files/PIE.htc);
}

input[type='checkbox'] {
	width: auto;
	/*margin-top: -2px;
    vertical-align: text-bottom;*/
	/*vertical-align: text-top;*/
	float: none !important;
}

input[type='text'],
input[type='password'] {
	padding: 4px 6px;
	border-radius: 0;
	/*height: 20px;*/
	color: #555555;
	/*line-height: 20px;*/
	font-size: 14px;
	margin-bottom: 10px;
	vertical-align: middle;
	position: relative;
	display: inline-block;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	box-shadow: inset 3px #f3f2ef;
	behavior: url(/files/PIE.htc);
}

input[type='text'],
input[type='password'],
input[type='textfield'],
textarea {
	border: 1px solid #d1ccbf;
	transition: border 0.2s linear, box-shadow 0.2s linear;
	box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
	background-color: white;
	position: relative;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border linear .2s, box-shadow linear .2s;
	-moz-transition: border linear .2s, box-shadow linear .2s;
	-o-transition: border linear .2s, box-shadow linear .2s;
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

textarea:focus {
	border-color: rgba(82, 168, 236, 0.8);
	outline: dotted thin;
	box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075), 0px 0px 8px rgba(82, 168, 236, 0.6);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

input[type='text']:focus {
	border-color: #a0a1a5;
	outline: dotted thin;
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.075), 0px 0px 8px rgba(82, 168, 236, 0.6);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}

select {
	height: 30px;
	line-height: 30px;
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

select {
	border: 1px solid #cccccc;
	width: 220px;
	background-color: white;
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

.form-horizontal .control-group {
	margin-bottom: 10px;
}

[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings.png");
}

[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings.png");
}

.icon-white {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.nav-pills>.active>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.nav-pills>.active>a>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.nav-list>.active>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.nav-list>.active>a>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.navbar-inverse .nav>.active>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.navbar-inverse .nav>.active>a>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-menu>li>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings.png");
}

[class^='icon-'] {
	background-position: 14px 14px;
	width: 14px;
	height: 14px;
	line-height: 14px;
	margin-top: 1px;
	vertical-align: text-top;
	display: inline-block;
	background-image: url("/img/glyphicons-halflings.png");
	background-repeat: no-repeat;
}

[class*=' icon-'] {
	background-position: 14px 14px;
	width: 14px;
	height: 14px;
	line-height: 14px;
	margin-top: 1px;
	vertical-align: text-top;
	display: inline-block;
	background-image: url("/img/glyphicons-halflings.png");
	background-repeat: no-repeat;
}

.icon-user {
	background-position: -168px 0px !important;
}

.icon-cog {
	background-position: -432px 0px !important;
}

.icon-remove-circle {
	background-position: -168px -96px !important;
}

.dropdown-menu>li>a:hover>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings.png");
}

.dropdown-menu>li>a:focus>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-menu>li>a:hover>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings.png");
}

.dropdown-menu>li>a:hover>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-menu>li>a:focus>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-menu>.active>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-menu>.active>a>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-submenu:hover>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-submenu:focus>a>[class^='icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-submenu:hover>a>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-submenu:focus>a>[class*=' icon-'] {
	background-image: url("/img/glyphicons-halflings-white.png");
}

.dropdown-menu {
	list-style: none;
	margin: 2px 0px 0px;
	padding: 5px 0px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	left: 0px;
	top: 100%;
	float: left;
	display: none;
	position: absolute;
	z-index: 1000;
	/*min-width: 160px;*/
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
	background-clip: padding-box;
	background-color: white;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	padding: 0px;
	margin: 0px;
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

.dropdown-menu.AddNote {
	top: 21px;
	background-color: rgb(243, 242, 239);
	border: 1px solid #9fa1a4;
	min-width: 171px;
}

.dropdown-menu>li>a {
	padding: 10px 20px;
	color: #003D79;
	line-height: 20px;
	clear: both;
	font-weight: normal;
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid #e2e1df;
}

.dropdown-menu>li>a:hover {
	color: #205589;
	text-decoration: none;
	background-image: none !important;
	background-repeat: repeat-x;
	background-color: #f3f2ef;
}

.dropdown-menu>li>a:focus {
	color: #003D79;
	text-decoration: none;
	/*background-image: linear-gradient(rgb(0, 136, 204), rgb(0, 119, 179)); background-repeat: repeat-x;*/
	background-color: #a0a1a5;
}

.alert {
	padding: 8px 35px 8px 14px;
	border-radius: 0;
	border: 1px solid #fbeed5;
	margin-bottom: 20px;
	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
	background-color: #fcf8e3;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	behavior: url(/files/PIE.htc);
}

.alert-no-padding-border {
	padding-left: 0px;
	padding-right: 0px;
	border-left-style: none;
	border-right-style: none;
}

.alert-indent {
	padding-left: 10px;
}

.alert-left-indent {
	padding-left: 25px;
}

/* The alert-error name clashes with a class name in bootstrap. Replace it with alert-error-colors as you come across it. */
.alert-error {
	border-color: #eed3d7;
	color: #b94a48;
	background-color: #f2dede;
}

/* The alert-error name clashes with a class name in bootstrap. Replace it with alert-error-colors as you come across it. */
.alert-error-colors {
	border-color: #eed3d7;
	color: #b94a48;
	background-color: #f2dede;
}

.alert-success {
	background-color: #f7f8e8;
	color: #6a6b6c;
	border: 1px solid #c4cd59;
}

span.error {
	color: #a84d10;
}

table {
	border-collapse: collapse;
	/*max-width: 100%;*/
	border-spacing: 0;
	background-color: transparent;
}

.table {
	/*width: 100%;*/
	margin-bottom: 20px;
	width: 990px;
}

.table-striped tbody tr.odd {
	background-color: #f3f2ef;
}

.table-striped tbody tr.even {
	background-color: rgb(249, 249, 249);
}

.table-striped tbody>tr:nth-child(2n + 2)>td {
	background-color: #f3f2ef;
}

.table-striped tbody>tr:nth-child(2n + 2)>th {
	background-color: #f3f2ef;
}

.breadcrumb {
	list-style: none;
	margin: 20px 0px 20px;
	padding: 0 15px 10px 15px;
	font-size: 12px;
	border-bottom: 1px solid #8f8f8f;
	background: none;
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

/* TFS #29857 Help Icon is inconsistent with others and causes usability issues*/
.ul_breadcrumb {
	margin: 0px 0px 20px !important;
}

.breadcrumb>li {
	display: inline-block;
}

.breadcrumb>li>a {
	color: #003D79;
}

.breadcrumb>li>.divider {
	padding: 0px 5px;
	color: #8f8f8f;
}

.breadcrumb>a.right>i.icon-question-sign {
	float: right;
}

.breadcrumb>.active {
	color: #60605b;
}

.breadcrumb .right {
	float: right;
}

.caret {
	width: 0px;
	height: 0px;
	vertical-align: top;
	border-top-color: #003D79;
	border-right-color: transparent;
	border-left-color: transparent;
	border-top-width: 4px;
	border-right-width: 4px;
	border-left-width: 4px;
	border-top-style: solid;
	border-right-style: solid;
	border-left-style: solid;
	display: inline-block;
	content: "";
}

.dropdown .caret {
	margin-top: 8px;
	margin-left: 8px;
}

.tooltip {
	line-height: 1.4;
	font-size: 13px;
	display: block;
	visibility: visible;
	position: absolute;
	z-index: 1050;
	opacity: 1;
}

.tooltip-save .tooltip-inner {
	width: 600px;
}

.alert-error.signin {
	float: right;
	width: 152px;
	position: absolute;
	right: 0;
	font-size: 10.5px;
	line-height: 12px;
	margin-top: -80px;
	margin-right: 15px;
	padding: 5px 7px;
	border-radius: 0;
	background: #f4dfcd;
	color: #c66005;
	border: 0;
	behavior: url(/files/PIE.htc);
}

/* Interior Page Header */
.header {
	margin: 30px 0 30px 0 !important;
}

.header .headerinfo-container {
	width: 432px;
	float: right;
}

.header .headerinfo-container .left {
	width: 265px;
	padding: 0 15px;
	border-left: 1px solid #d1ccbf;
	height: 70px;
	float: left;
	text-align: left;
}

.header .headerinfo-container .left .top-buttons {
	width: 285px;
	float: left;
}

.header .headerinfo-container .left .top-buttons .dropdown-menu {
	font-size: 12px;
	margin-top: 0;
}

.header .headerinfo-container .left .top-buttons .dropdown-menu li>a {
	padding: 5px 20px;
}

.header .headerinfo-container .left .top-buttons a.grey {
	background: #60605b;
	color: #fff;
	font-size: 11.25px;
	padding: 3px 14px 3px 5px;
	border-radius: 0 8px 0 0;
	text-decoration: none;
	margin: 0 4px 0 0;
	position: relative;
	behavior: url(/files/PIE.htc);
}

.header .headerinfo-container .left .top-buttons .header-alert {
	float: right;
	width: 70px;
	margin-top: 2px;
}

.header .headerinfo-container .left .top-buttons .header-alert a.alert-message {
	width: 18px;
	height: 16px;
	display: block;
	background: url("/img/icon-alert-message.png") 0 0 no-repeat;
	color: #fff;
	text-decoration: none;
	text-align: center;
	font-size: 10px;
	font-weight: bold;
	margin: 0 0 0 5px;
	float: left;
	line-height: 10.5px;
}

.header .headerinfo-container .left .top-buttons .header-alert a.alert-icon {
	float: left;
	margin-top: -3px;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu {
	background: #933311;
	color: #fff;
	width: 260px;
	border: 0;
	margin-top: 6px;
	padding: 0;
	left: -95px;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu span.alert-arrow {
	background: url("/img/alertbox-arrow-top.png") no-repeat;
	width: 8px;
	height: 4px;
	position: absolute;
	left: 48%;
	top: -4px;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li {
	border-bottom: 1px solid #fff;
	padding: 10px 10px 0px 10px;
	position: relative;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li a.close {
	width: 10px;
	height: 10px;
	position: absolute;
	right: 10px;
	top: 8px;
	padding: 0;
	opacity: 1;
	border: 0;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li a.close:hover {
	background: 0;
	border: 0;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li div {
	padding: 0 0 0 10px;
	margin: 5px 0 15px 30px;
	border-left: 1px solid #fff;
	position: relative;
	font-size: 13px;
	line-height: 17px;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li span.alert-icon {
	background: url("/img/icon-alert-white.png") no-repeat;
	width: 19px;
	height: 18px;
	position: absolute;
	left: -30px;
	top: 38%;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li>div>a {
	display: inline-block;
	color: #fff;
	text-decoration: underline;
	padding: 0;
	margin: 0;
	border-bottom: 0;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li>div>a:hover {
	background: none;
}

.header .headerinfo-container .left .top-buttons .header-alert ul.dropdown-menu li.last {
	border-bottom: 0;
}

.header .headerinfo-container .left .welcome {
	padding-left: 34px;
	background: url("/img/icon-user.png") 0 70% no-repeat;
	height: 40px;
	clear: both;
	float: left;
	margin-top: 14px;
}

.header .headerinfo-container .left .welcome .dropdown-toggle {
	color: #003D79;
	font-size: 18px;
	line-height: 17px;
}

.header .headerinfo-container .left .welcome .dropdown-toggle a {
	color: #003D79;
}

.header .headerinfo-container .left .welcome .dropdown-toggle .welcometext {
	color: #60605b;
	font-size: 14px;
}

.header .headerinfo-container .right {
	width: 120px;
	padding: 0 0 0 15px;
	border-left: 1px solid #d1ccbf;
	height: 70px;
	float: right;
	text-align: left;
}

.header .headerinfo-container .right h4 {
	color: #003D79;
	font-size: 11px;
	margin: 0;
	padding: 0;
	line-height: 13px;
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.header .headerinfo-container .right ul {
	margin: 0;
}

.header .headerinfo-container .right ul li {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 11px;
	line-height: 12px;
	color: #60605b;
}

.header .headerinfo-container .right ul li a {
	color: #60605b;
}

.close {
	color: #cecece;
	line-height: 20px;
	font-size: 20px;
	font-weight: bold;
	float: right;
	opacity: 1;
	text-shadow: 0px 1px 0px#fff;
	border: 0px;
}

/* Edit User Page Form */
table.table {
	border-top: 0;
}

table.table tr:nth-child(odd) {
	background-color: #fafafa;
}

table.table tr:hover {
	background-color: #ececec;
}

table.table td {
	border-top: 0;
	margin-top: 0;
	padding-top: 0;
}

table.table td.left {
	width: 19%;
}

table.table td.center {
	width: 60%;
}

table.table td.right {
	width: 22%;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	border-left: 1px solid #a0a1a5;
}

::-webkit-scrollbar-thumb {
	background-color: #a0a1a5;
}

.form-control.is-valid,
.form-control.is-invalid {
	font-size: 14px;
	height: 30px;
}

.form-control.is-valid {
	margin-bottom: 32px !important;
}

.btn {
	cursor: pointer;
	border: 0px !important;
	z-index: 0px !important;
}

.tab-content {
	background: #f3f2ef;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
}

.width100P {
	width: 100%;
}

.contentBody {
	background-color: white !important;
	padding: 10px !important;
}

.btnClass {
	background: #003D79 !important;
	color: #fff !important;
	font-size: 16px !important;
	border: 0 !important;
	padding: 7px 16px !important;
	text-shadow: 1px 1px 1px #60605b !important;
	border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-webkit-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
}

.btnClass:hover {
	background: #205589 !important;
}

.dataTable thead tr th {
	color: #003D79 !important;
	background-color: white !important;
}

.spinnerPage {
	height: 600px !important;
	text-align: center !important;
}

.nav-header {
	padding: 3px 15px;
	color: #999;
	text-transform: uppercase;
	line-height: 20px;
	font-size: 11px;
	font-weight: bold;
	display: block;
	text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
}

i.gear {
	margin-right: 5px !important;
}

.lnkCancelSelection {
	margin-right: 10px !important;
}

.btn-LogOut {
	color: #fff !important;
	background-color: #6c757d !important;
	border-color: #6c757d !important;
}

.btn-LogOut:hover {
	color: #fff !important;
	background-color: lighten(#6c757d, 5%) !important;
	border-color: lighten(#6c757d, 5%) !important;
}

.row-fluid .span12 {
	margin-bottom: 18px;
}

.backgroundWhite {
	background-color: white !important;
}

.boxBody {
	margin-right: 20px;
}

.boxContent {
	margin-left: 30px;
	margin-right: 30px;
	padding-top: 80px !important;
}

.marginRight70 {
	margin-right: 70px !important;
}

#pageDropDown {
	margin-left: 0px !important;
}

.footer-wide .footer .whitelabel-footer {
	float: left;
}