#batch-button {
    background: #003D79 !important;
    color: #fff !important;
    padding: 3px 20px !important;
    margin-right: 2px !important;
    display: inline-block !important;
    text-shadow: -1px 1px 2px #43334b !important;
}

#batchButton {
    background: #8f8f8f !important;
    color: #fff !important;
    padding: 3px 20px !important;
    margin-right: 2px !important;
    display: inline-block !important;
    text-shadow: -1px 1px 2px #43334b !important;
}


#batch-button:hover {
    text-decoration: underline !important;
}

#batchUploadHeader {
    font-size: 30px;
    line-height: 36px;
    color: #003D79;
    font-weight: 200;
    padding: 0 20px 0 0;
}

#batchLabel {
    color: #c66005;
    font-size: medium;
    margin-left: 18px;
}

#batchNameLabel {
    margin-left: 18px;
    font-size: medium;
}

.row {
    margin-top: 10px;
}



#batchText {
    margin-right: 180px;
    width: 800px;
}

#btnSubmit {
    margin-top: 40px;
    width: 25% !important;
    padding: 8px 15px 8px 10px !important;
    background: #003D79 !important;
    color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    text-shadow: none !important;
}

#uploadFile {
    margin-left: 18px;
    font-size: medium;
    margin-top: 25px;
    margin-bottom: 35px;
}

#continueButton {
    margin-top: -62px;
    width: 25% !important;
    margin-right: 125px;
}

#exportButton {
    margin-top: -22px;
    width: 25% !important;
    margin-right: 331px;
}


#btnExport {
    margin-top: -22px;
    width: 25% !important;
    padding: 8px 15px 8px 10px !important;
    background: #003D79 !important;
    color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    text-shadow: none !important;
    margin-right: 331px;
}

.import-batch-modal {
    height: 500px !important;
    width: 700px !important;
}

.import-batch-modalForError {
    height: 500px !important;
    width: 710px !important;
}



#btnContinue {
    margin-top: -62px;
    width: 25% !important;
    padding: 8px 15px !important;
    background: #96938e !important;
    color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    text-shadow: none !important;
    z-index: 1050 !important;
    margin-right: 125px;
}

#btnCancel {
    margin-top: 40px;
    width: 25% !important;
    padding: 8px 15px !important;
    background: #96938e !important;
    color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    text-shadow: none !important;
    z-index: 1050 !important;
    margin-right: 125px;
}


#btnUpload {
    margin-right: 250px;
    margin-top: 25px;
    margin-bottom: 35px;
}

#feedback {
    margin-left: -100px;
    max-width: 200px;
    margin-right: 20px;
}

#feedbackForFile {
    margin-top: -11px;
    margin-left: -130px;
}

#pageForError {
    margin-left: 1px !important;
}

.dropDown-menu {
    margin-left: 45px;
}

#pageDropDown {
    margin-left: 38px;
}

.clearfix {
    margin-top: 10px !important;
}

#searchButton {
    margin-top: 40px;
    width: 25% !important
}

#showPageSizeForError {
    margin-left: 7px !important;
}

#showPagination {
    margin-right: 6px;
}

.errorDataTable {
    overflow-y: auto;
    height: 200px;
    margin-top: 10px;
    width: 700px;
    margin-left: -22px;
}

#labelForError {
    margin-left: 18px;
    margin-top: -20px;
}

#alertImg {
    margin-left: 18px;
}

#closeModalForUpload {
    margin-left: 400px;
}

#closeModalForError {
    margin-left: 342px;
}

#headerForError {
    display: inline-flex;
}


.errorDataTable .table {
    border: 1px solid #ced4da !important;
}

.errorDataTable table.table td {
    z-index: 1;
    word-break: break-word;
}

#closeModalForUpload:hover {
    cursor: pointer;
}

#closeModalForError:hover {
    cursor: pointer;
}