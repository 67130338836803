.TopNavTabs {
	padding-top: 10px;
}

.TopNavTabs .nav.nav-tabs {
	margin-bottom: 0;
	border-bottom: 0;
	padding-bottom: 0px;
}

.TopNavTabs .nav.nav-tabs li.active a {
	background: #f3f2ef;
	border: 0;
	position: relative;
	color: #003D79;
}

.TopNavTabs .nav.nav-tabs li {
	margin-right: 2px;
}

.TopNavTabs .nav.nav-tabs li a {
	padding: 10px 55px 20px 15px;
	background: #d7d7d6;
	line-height: 15px;
	border: 0;
	position: relative;
	border-radius: 8px 8px 0 0;
	-moz-border-radius: 8px 8px 0 0;
	-ms-border-radius: 8px 8px 0 0;
	-webkit-border-radius: 8px 8px 0 0;
	behavior: url(/files/PIE.htc);
	cursor: pointer;
	text-decoration: none;
}

.TopNavTabsActiveTab {
	background: #f3f2ef 82% 50% no-repeat !important;
	position: relative;
}