.breadcrumb-main {
	background-color: white !important;
	width: 980px !important;
}

.breadcrumb {
	width: 920px !important;
	border-bottom: 0px !important;
	margin-bottom: 0px !important;
}

.helpClass {
	list-style: none;
	border-bottom: 1px solid #8f8f8f;
	background: none;
	border-radius: 0;
	behavior: url(/files/PIE.htc);
}

.requestHeader {
	padding: 0 0 15px 15px;
	border-bottom: 1px solid #d1ccbf;
	margin-bottom: 15px;
	margin-top: 10px !important;
	float: left;
	width: 98.5%;
}

.requestHeader h2 {
	color: #003D79;
	margin-top: 0;
	line-height: 36px;
}

.requestHeader label {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 200;
	font-size: 18px;
}

#workspaceLink:hover {
	text-decoration: underline !important;
	cursor: pointer;
}