.benefitinformation thead tr th {
	background: #003D79;
	color: #fff;
	padding: 8px 12px;
	height: 20px;
}

.benefitinformation td:last-child {
	border-right: 1px solid #d1ccbf;
}

.benefit-information-title {
	float: left;
	color: #fff;
}
