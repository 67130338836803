.drawer-panel {
    color:#60605b !important;
    font-family: "Helvetica Neue",Arial,Helvetica,sans-serif !important;
font-size: 13px !important;
width:200px !important;
}
i.request {
    width:26px;
    height:18px;
    display:inline-block;
    background:url("../../img/HomePage2015/icon-nav-request.png") top left no-repeat;
    margin-right:10px;
    margin-top:2px;
    float:left;
}

i.document {
    width:15px;
    height:18px;
    display:inline-block;
    background:url("../../img/HomePage2015/icon-nav-document.png") top left no-repeat;
    margin-right:11px;
    margin-top:2px;
    float:left;
}
i.gear {
    width:21px;
    height:21px;
    display:inline-block;
    background:url("../../img/HomePage2015/icon-nav-gear.png") top left no-repeat;
    margin-right:11px;
    margin-top:2px;
    float:left;
}
i.batches {
    width:21px;
    height:21px;
    display:inline-block;
    background:url("../../img/HomePage2015/icon-nav-batches.png") top left no-repeat;
    margin-right:5px;
    margin-top:2px;
    float:left;
}
.width100P
{
    width:100% !important;
}