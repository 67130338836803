h1 {
	color: #003D79;
}

.results {
	.filter-heading {
		background-color: #003D79;
		color: #fff;
		text-align: right;
		padding-top: 10px;
	}
}

.searchByPayerFilter {
	margin-bottom: 30px !important;
}

.anchorClassForRequestHistory {
	color: white !important;
}

.anchorClassForRequestHistory:hover {
	text-decoration: underline;
	cursor: pointer;
}

.input {
	border-radius: 0.3rem;
	border-top-left-radius: 0.3rem !important;
	border-top-right-radius: 0.3rem !important;
	border-bottom-right-radius: 0.3rem !important;
	border-bottom-left-radius: 0.3rem !important;
	width: 10px !important;
}

.btn-btnSaveColor {
	background-color: #003D79;
	color: #fff;
	padding: 13px;
	font-size: 12px;
}

.dobInput {
	width: 120px !important;
	padding: 4px 1px;
	margin: 4px;
	font-size: 14px;
}

#filterHistoryHeader {
	height: 15px;
}

.dobInputValid {
	border-radius: 0.3rem !important;
	border-color: #28a745 !important;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	font-size: 14px !important;
	height: 32px !important;
	margin-bottom: 32px !important;
}

.dobInputInvalid {
	border-radius: 0.3rem !important;
	border-color: #dc3545 !important;
	padding-right: calc(1.5em + 0.75rem) !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.37rem) calc(0.75em + 0.37rem);
	font-size: 14px !important;
	height: 32px !important;
	width: 70% !important;
}

.show {
	display: block !important;
}

.input-110 {
	width: 121px;
	margin: 4px;
}

table.dataTable tbody th,
table.dataTable tbody td {
	z-index: 1;
	word-wrap: break-word;
}

.filterButton {
	background: #003D79 url(../../img/icon-filter-history.png) 15% 52% no-repeat !important;
	color: #fff !important;
	border: 0 !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	padding: 30px 30px 30px 55px !important;
	height: 20px !important;
	font-size: 18px !important;
	text-shadow: -1px 1px 1px #757c2a !important;
	margin-bottom: 12px !important;
	width: 120px !important;
}

#btnSubmitForRequestHistory {
	width: 25% !important;
	padding: 8px 15px 8px 10px !important;
	background: #003D79 !important;
	color: #ffffff !important;
	font-size: 16px !important;
	border-radius: 0 !important;
	border: 0 !important;
	text-shadow: none !important;
}

#btnCancelForRequestHistory {
	width: 25% !important;
	padding: 8px 15px !important;
	background: #96938e !important;
	color: #ffffff !important;
	font-size: 16px !important;
	border-radius: 0 !important;
	border: 0 !important;
	text-shadow: none !important;
	z-index: 1050 !important;
}

#bodyContentForFilterHistoryTextbox {
	height: 90px;
}

#labelForDate {
	font-size: 15px;
	font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.filterButton {
	background: #003D79 url(../../img/icon-filter-history.png) 15% 52% no-repeat !important;
	color: #fff !important;
	border: 0 !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	padding: 30px 30px 30px 55px !important;
	height: 20px !important;
	font-size: 18px !important;
	text-shadow: -1px 1px 1px #757c2a !important;
	margin-bottom: 12px !important;
	width: 120px !important;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

	.react-datepicker__month-read-view--down-arrow,
	.react-datepicker__month-year-read-view--down-arrow,
	.react-datepicker__year-read-view--down-arrow {
		margin-left: -20px;
		left: 15px;
		top: 6px;
	}

	.react-datepicker__month-dropdown-container span {
		margin-right: 20px;
	}
}

.dob::-ms-clear {
	width: 0;
	height: 0;
}

#filters {
	background: #d7d7d6;
	color: #60605b;
	padding: 1px 4px;
	font-size: 12px;
	display: block;
	float: left;
	position: relative;
	margin-top: 5px;
	margin-left: 15px;
}

#filterClose {
	margin: 5px 0 0 12px;
	position: relative;
	width: 11px;
	height: 11px;
	display: block;
	float: right;
	background: url(../../img/icon-history-filters-x-button.png) no-repeat !important;
}

#filters:hover {
	cursor: pointer;
}

#closeModalForFilter:hover {
	cursor: pointer;
}

#chkNpi:hover {
	cursor: pointer;
}

#clearAll {
	font-size: 12px;
	margin: 5px 0 15px 15px;
	float: left;
	color: #003D79;
	outline: 0;
}

#clearAll:hover {
	text-decoration: underline;
	cursor: pointer;
}

.width130p {
	width: 130px !important;
}

.patientSearch {
	background-color: white !important;
	width: 708px !important;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 60px;
	margin-left: 15px !important;
}

.patientSearch label {
	color: #003D79;
	font-size: 16px;
	margin-right: 5px;
	margin-bottom: 8px;
	font-weight: normal;
}

.btnWidth {
	width: 95px !important;
}

.react-datepicker__month-select {
	width: 55px !important;
	height: 15px !important;
}

.react-datepicker__year-select {
	width: 60px !important;
	height: 15px !important;
}

.react-datepicker-popper {
	z-index: 5;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {

	.react-datepicker__year-select:focus,
	.react-datepicker__month-select:focus {
		border: #2a87d0 solid 1px !important;
	}
}

#page {
	margin-left: 10px;
}

#npiName {
	float: left;
	margin-left: 10px;
}

#facilityMessage {
	color: red;
}

#firstNameSearchFilter {
	width: 121px;
	margin: 4px;
}

#lastNameSearchFilter {
	width: 121px;
	margin: 4px;
}

#ssnSearchFilter {
	width: 121px;
	margin: 4px;
}

#dob {
	width: 121px;
	margin: 4px;
}

#searchRequestHistoryButton {
	margin-top: -66px !important;
	margin-left: 575px;
	width: 21% !important;
}

#requestDataForName {
	margin-left: 13px;
	word-break: break-all;
	margin-right: 10px;
}

#requestDataForName .history-facility {
	display: unset;
}

.mdlRequestHistory {
	width: 740px !important;
	padding: 15px 15px 15px 15px !important;
	background: #f3f2ef !important;
	top: 3% !important;
}

.mdlForExportRequest {
	width: 740px !important;
}

#btnCancelForExportRequest {
	width: 25% !important;
	padding: 8px 15px 8px 10px !important;
	background: #003D79 !important;
	color: #ffffff !important;
	font-size: 16px !important;
	border-radius: 0 !important;
	border: 0 !important;
	text-shadow: none !important;
}

.mdlForExportRequest .modal-content {
	border: 1px groove;
}

#codeTabs {
	margin-left: -48px;
	list-style: none;
	margin-top: 15px;
}

#modalFooter {
	background: #f3f2ef;
	border-top: 0px;
	border-radius: 0;
	box-shadow: none;
}

#tabForForm {
	background: #fff;
	border: 0;
}

#tabsection {
	width: 23.4% !important;
}

#contentSection {
	width: 555px;
	margin-left: 35px;
}

#headingForFilter {
	color: #003D79 !important;
	font-size: 23px !important;
	padding: 0 !important;
	margin-bottom: 20px !important;
	line-height: 36px !important;
	padding: 20px 0 0 15px !important;
}

#headingForExportRequestModal {
	font-size: 30px;
	line-height: 36px;
	color: #003D79;
	font-weight: 200;
	padding: 0 20px 0 0;
	margin-left: 21px;
	margin-top: 23px;
}

#tabLink {
	background: none !important;
	border-radius: 0 !important;
	font-size: 18px !important;
	line-height: 22px !important;
	padding: 10px 0 !important;
	display: block !important;
}

#tabLink.active {
	color: #8f8f8f;
}

#deleteImg:hover {
	cursor: pointer;
}

#tabLink:hover {
	cursor: pointer;
}

#selectAll {
	color: #fff;
	margin-right: 6px;
	padding-top: 12px;
}

#selectAll a:hover {
	text-decoration: underline;
}

#filterHistoryHeader .abc {
	height: 40px;
	background-color: #003D79;
	margin-bottom: 10px;
	margin-right: 4px;
	text-align: right;
	margin-top: -29px;
}

#divForCheckBox {
	color: #60605b;
	margin-left: 20px;
}

#divForRequestHistoryTable tr:nth-child(odd) {
	background-color: #f4f4f4;
}

#divForRequestHistoryTable tr:hover {
	background-color: #ececec;
}

#divForRequestHistoryTable tr td:nth-child(6) {
	padding-left: 66px !important;
}

#divForRequestHistoryTable tr td:first-child {
	padding-left: 73px !important;
}

#divForRequestHistoryTable tr td {
	word-break: break-word;
}

#box:nth-child(odd) {
	background-color: #f3f2ef;
}

#tableForReport td:nth-child(3) {
	padding-left: 21px !important;
}

#tableForReport tr:hover {
	background: none !important;
}

#tableForReport {
	border: 1px #ced4da !important;
}

#checkBoxesForNpis {
	margin-left: 14px;
	margin-top: 34px;
	height: 388px;
	margin-right: 4px;
	overflow-y: auto;
	overflow-x: hidden;
	word-break: break-all;
}

#statusBox {
	float: left;
	padding: 15px;
}

#checkBoxesStatus {
	margin-top: 34px;
	margin-right: 4px;
	height: 225px;
}

#filterHistoryHeader .dateClass {
	height: 40px;
	background-color: #003D79;
	margin-bottom: 10px;
	text-align: right;
	margin-top: -110px;
	margin-right: 5px;
}

#textBoxForFilterHistory {
	margin-top: 55px;
	margin-left: 12px;
}

#tableStyle {
	margin-top: 50px;
}

#exportName {
	float: left;
	margin-left: 10px;
	font-size: 18px;
	margin-top: 7px;
}

#divForRequestHistoryTable thead:first-child tr:first-child th:hover {
	text-decoration: underline !important;
	cursor: pointer;
}

#reportDropDown {
	margin-left: 20px;
	padding-top: 4px;
	width: 50px;
}

#linkForExport {
	margin-left: 30px;
	color: #60605b !important;
}

#linkForExport:enabled:hover {
	text-decoration: underline !important;
}

#linkForExport:active {
	background: none !important;
	border: none !important;
	outline: none !important;
}

#headerForMenu {
	background: #003D79;
	height: 22px;
	margin-left: 6px;
	margin-right: 6px;
	color: white;
}

#reportButton:focus {
	color: #003D79;
	text-decoration: underline;
	background: white;
}

#reportButton {
	color: white !important;
	background-color: #003D79;
	height: 20px;
	border: none;
	text-decoration: none;
}

#imgDown {
	background: url('../../img/arrow-down-white.png') center no-repeat;
	width: 12px;
	height: 8px;
	margin-left: 15px;
	padding-top: 1px;
	display: inline-block;
	margin: 0;
	float: none;
	text-align: center;
	padding-left: 10px;
}

#dropDownForDateRange {
	width: 140px !important;
	height: 23px;
	margin-left: 133px;
	margin-top: -27px;
	font-size: 15px;
}

.validationCtnr {
	position: relative !important;
}

.downloadReportLink,
.btn.downloadReportLink {
	text-align: left;
	box-shadow: none;
	border: 0;
	width: 100%;
}

.downloadReportLink:hover {
	text-decoration: underline;
}

#reviewedData {
	word-break: break-all;
	margin-right: 10px;
}

.disabled {
	opacity: 0.65 !important;
	pointer-events: none !important;
}