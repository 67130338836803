#batchesTable {
    // width:760px;
    margin-right: 1px;
}

#btnFilterForBatches {
    width: 32% !important;
    padding: 8px 15px 8px 10px !important;
    background: #003D79 !important;
    color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    text-shadow: none !important;
    margin-left: 91px;
    margin-top: 20px;
}

#batchLinks {
    color: #003D79;
}

#batchLinks:hover {
    text-decoration: underline;
    cursor: pointer;
}

#clearAllForBatches {
    font-size: 12px;
    margin: 5px 0 15px 15px;
    float: left;
    color: #003D79;
    outline: 0;
    margin-top: -25px;
}

#clearAllForBatches:hover {
    text-decoration: underline;
    cursor: pointer;
}

.anchorTagForBatchesHistory:hover {
    text-decoration: underline;
    cursor: pointer;
}

#batchLinks:hover {
    text-decoration: underline;
}

#batchesTable tr:nth-child(odd) {
    background-color: #f4f4f4;
}

#batchesTable tr:hover {
    background-color: #ececec;
}

#batchesTable td {
    font-size: 13px;
}

#npiName {
    margin-left: 10px;
}

.batchesHistory {
    border: 1px groove;
    margin-left: 8px;
    margin-right: 8px;
    height: 300px;
    overflow-x: auto;
    overflow-y: auto;
}

#dropDownForDateRangeFilter {
    height: 20px;
    font-size: 14px;
    margin-top: 10px;
    width: 120px !important;
}

.batchesHistoryTransaction {
    border: 1px groove;
    margin-left: 8px;
    margin-right: 8px;
    height: 260px;
    margin-top: 30px;
}

#tableStyleForBatches {
    margin-top: 30px;
    margin-bottom: 30px;
}

#pageForBatch {
    margin-left: 5px;
}

#batchesTable {
    width: 768px;
}

#divForFilters {
    width: 256px;
    margin-top: 81px;
    margin-left: 6px;
}


.react-datepicker__month-select {
    width: 55px !important;
    height: 15px !important;
}

.react-datepicker__year-select {
    width: 60px !important;
    height: 15px !important;
}

.react-datepicker-popper {
    z-index: 5;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {

    .react-datepicker__year-select:focus,
    .react-datepicker__month-select:focus {
        border: #2a87d0 solid 1px !important;
    }
}


@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__year-read-view--down-arrow {
        margin-left: -20px;
        left: 15px;
        top: 6px;
    }

    .react-datepicker__month-dropdown-container span {
        margin-right: 20px;
    }
}

#divForBatchesTable tr td:first-child {
    padding-left: 20px !important;
}

#divForBatchesTable {
    margin-right: 15px;
}

.dob::-ms-clear {
    width: 0;
    height: 0;
}

.mdlForExportRequestForBatches {
    width: 740px !important;
}

.mdlForExportRequestForBatches .modal-content {
    border: 1px groove;
}

#headingForExportDeleteModalFOrBatches {
    font-size: 30px;
    line-height: 36px;
    color: #003D79;
    font-weight: 200;
    padding: 0 20px 0 0;
    margin-left: 21px;
    margin-top: -23px;
}

#headingForExportRequestModalFOrBatches {
    font-size: 30px;
    line-height: 36px;
    color: #003D79;
    font-weight: 200;
    padding: 0 20px 0 0;
    margin-left: 21px;
    margin-top: 23px;
}

#batchMessage {
    color: red
}

.batchesHistoryTransactionStatus {
    border: 1px groove;
    margin-left: 8px;
    margin-right: 8px;
    height: 190px;
    margin-top: 30px;
    overflow-x: auto;
    overflow-y: auto;
}

.batchesHistoryTransactionDate {
    border: 1px groove;
    margin-left: 8px;
    margin-right: 8px;
    height: 225px;
    margin-top: 30px;
}


.validationCtnr {
    position: relative !important;
}

#batchesTable tr td {
    word-break: break-word;
}

#closeModalForDeletion {
    cursor: pointer;
    margin-left: 688px;
}

.disabled {
    opacity: 0.65 !important;
    pointer-events: none !important;
}