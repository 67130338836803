@import '../../scss/custom.scss';

.input {
    border-radius: 0.3rem;
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
    width: 100px !important;
}

#requestForm input[type='text']:not(#dobBegin, #dobEnd) {
    height: 30px !important;
    padding-right: calc(1.5em + .75rem);
}

.dobInputValid {
    border-radius: 0.3rem !important;
    border-color: #28a745 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    font-size: 14px !important;
    height: 32px !important;
    margin-bottom: 32px !important;
    width: 84% !important;
}


.dobInputInvalid {
    border-radius: 0.3rem !important;
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.37rem) calc(0.75em + 0.37rem);
    font-size: 14px !important;
    height: 32px !important;
    width: 70% !important;
}

.iDobInput {
    border-radius: 0.3rem !important;
    font-size: 14px !important;
    height: 30px !important;
    margin-top: 0px !important;
    padding-right: calc(1.5em + .75rem);
    width: 70% !important;
}

.show {
    display: block !important;
}

.hideDateValidation {
    display: none !important;
}

.btnSaveColor {
    color: #003D79;
}

.miText {
    width: 70% !important;
}

.btnCancelColor {
    color: #96938E;
}

.height60 {
    margin-top: 0px !important;
    height: 90px !important;
}

.paddingRight20 {
    padding-right: 25%;
}

.paddingRight20p {
    padding-right: 20px !important;
}

.ssnText {
    width: 20% !important;
}

.phoneText {
    width: 40% !important;
}

.addressText {
    width: 94% !important;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__year-read-view--down-arrow {
        margin-left: -20px;
        left: 15px;
        top: 6px;
    }

    .react-datepicker__month-dropdown-container span {
        margin-right: 20px;
    }
}

.react-datepicker__month-select {
    width: 50px !important;
    height: 15px !important;
}

.react-datepicker__year-select {
    width: 55px !important;
    height: 15px !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {

    .react-datepicker__year-select:focus,
    .react-datepicker__month-select:focus {
        border: #2a87d0 solid 1px !important;
    }
}

.dob::-ms-clear {
    width: 0;
    height: 0;
}



@supports (-moz-appearance:none) {

    .was-validated #dropDown:invalid,
    #dropDown.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 1.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #dropDown:valid,
    #dropDown.is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 1.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated,
    #states:invalid,
    #states.is-invalid {
        border-color: $control-invalid-border-color;
        padding-right: $control-padding-right;
        background-image: $control-invalid-background-image;
        background-repeat: $control-background-repeat;
        background-position: $control-background-position;
        background-size: $control-background-size;
    }

    .was-validated #states:valid,
    #states.is-valid {
        border-color: $control-valid-border-color;
        padding-right: $control-padding-right;
        background-image: $control-valid-background-image;
        background-repeat: $control-background-repeat;
        background-position: $control-background-position;
        background-size: $control-background-size;
    }

    .was-validated,
    #gstates:invalid,
    #gstates.is-invalid {
        border-color: $control-invalid-border-color;
        padding-right: $control-padding-right;
        background-image: $control-invalid-background-image;
        background-repeat: $control-background-repeat;
        background-position: $control-background-position;
        background-size: $control-background-size;
    }

    .was-validated #gstates:valid,
    #gstates.is-valid {
        border-color: $control-valid-border-color;
        padding-right: $control-padding-right;
        background-image: $control-valid-background-image;
        background-repeat: $control-background-repeat;
        background-position: $control-background-position;
        background-size: $control-background-size;
    }

    .was-validated #transactionTypes:invalid,
    #transactionTypes.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 1.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #transactionTypes:valid,
    #transactionTypes.is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 1.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .btn.save {
        width: 100%;
        padding: 8px 15px 8px 10px;
        background: #003D79;
        color: #ffffff !important;
        font-size: 16px;
        border-radius: 0;
        border: 0;
        text-shadow: none;
    }

    .btn.cancel {
        width: 100%;
        padding: 8px 15px;
        background: #96938e;
        color: #ffffff !important;
        font-size: 16px;
        border-radius: 0;
        border: 0;
        text-shadow: none;
        z-index: 1050;
    }

    .btn.save:hover {
        background: #205589;
    }

    .btn.cancel:hover {
        background: #7d7d7d;
    }
}

.cityCol {
    margin-left: 30px !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {

    .was-validated #dropDown:invalid,
    #dropDown.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #dropDown:valid,
    #dropDown.is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated,
    #states:invalid,
    #states.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #states:valid,
    #states.is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    #states {
        padding: 4px 6px;
        height: 30px;
        border: 1px solid #d1ccbf;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        padding-right: calc(1.5em + .75rem);
    }

    #gstates {
        padding: 4px 6px;
        height: 30px;
        border: 1px solid #d1ccbf;
        box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        padding-right: calc(1.5em + .75rem);
    }

    .was-validated,
    #gstates:invalid,
    #gstates.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #gstates:valid,
    #gstates.is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #transactionTypes:invalid,
    #transactionTypes.is-invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .was-validated #transactionTypes:valid,
    #transactionTypes.is-valid {
        border-color: #28a745;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(1.375em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}

#ddFacilities {
    background-position: right calc(0.75em + 0.375rem) center;
}
