@import '../../../scss/custom.scss';
div.debug-panel {
    div.debug-panel-toggle {
        font-weight: bold;
    }
    div.debug-panel-content {
        font-family: monospace;
        background-color: $green;
        border: 2px solid #000;
    }
    position: 'absolute';
    margin-bottom: '-10px';
    bottom: '0';
    margin-right: '-10px';
}
