.bottom-border-td {
    border-bottom: 1px solid #d1ccbf;
}

.cdResponsePage .service-types-table tr.summarySection td:last-child {
    border-right: 1px solid #d1ccbf;
    border-left: 0;
    background: #f2f5da;
}

.cdResponsePage .service-types-table tr.summarySection td:first-child {
    background: #f2f5da;
    border-right: 0;
}

.cdResponsePage .service-types-table tbody>tr>td:nth-child(2n+1) {
    background-color: #f3f2ef;
    border-left: 1px solid #d1ccbf;
    border-right: 1px solid #d1ccbf;
    color: #003D79;
}

.cdResponsePage .service-types-table tbody tr td {
    padding: 10px 20px;
    vertical-align: top;
    margin: 2px;
    border-top: 0;
    background: #fff;
    border-right: 1px solid #d1ccbf !important;
}

.cdResponsePage .service-types-table tr.summaryDisplaying td:last-child {
    border-right: 1px solid #d1ccbf;
}

.cdResponsePage .service-types-table tr.summaryDisplaying td:first-child {
    width: 50%;
    background: #fff;
    border-right: 0;
    color: #96938e;
}

.cdResponsePage .service-types-table tr.summarySection .summaryHide,
.summaryDetails {
    text-align: right;
}

.cdResponsePage .service-types-table thead {
    background: #003D79;
    color: #fff;
}

.cdResponsePage .response-table.status-alert thead {
    background: #933311;
}

.cdResponsePage .response-table.status-alert tbody td {
    background: #efe1dc;
}

.cdResponsePage .response-table.status-alert tbody>tr>td:nth-child(2n+1) {
    background-color: #efe1dc;
}