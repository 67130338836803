.patientdetails {
	width: 100% !important;
	padding: 0%;
	border: 1px solid #d1ccbf;
	height: 100%;
}

.patientdetails .header {
	background: #003D79;
	color: #fff;
	padding: 0.5em 0.6em;
	font-size: 1.2em;
	margin: 0% !important;
	height: auto;
}

.patientdetails .col-5 {
	padding-left: 2em;
	max-width: 8em;
}

.patientdetails .col-4 {
	padding-left: 2em;
}

.patientdetails .col-12 {
	padding-left: 2em;
	max-width: fit-content;
}

.patientdetails .col-3 {
	padding-right: 0px;
	padding-left: 10px;
	overflow-wrap: break-word;
}

.patientdetails .col-7,
.patientdetails .col-6 {
	flex-wrap: wrap;
	overflow-wrap: break-word;
	word-break: break-all;
	padding-left: 10px;
}

.patientdetails .showDemographicData {
	color: green;
	font-weight: bold;
}

.patientdetails .hideDemographicData {
	display: none;
}

.patientdetails .payorMismatchedText {
	color: #e17e27;
	font-weight: bolder;
}

.dnldX12color {
	color: #fff !important;
}

#dnldX12Links {
	text-align: right;
	text-decoration: underline;
	cursor: pointer;
}

.imgRedFlagsAndWarnings {
	padding-right: 5px;
	padding-bottom: 5px;
}

.redFlagsAndWarnings {
	margin-left: 4%;
}

.redFlagsAndWarnings label {
	color: #555545;
	background-color: #fff;
	border-radius: 5px;
	padding-left: 10px !important;
	padding-right: 10px !important;
	font-weight: normal;
	width: fit-content;
	max-width: 90% !important;
	margin-left: 20px;
	margin-right: 10px;
}

.dvRedFlagsAndWarnings {
	margin-bottom: 10px;
}

.redFlags,
.warnings {
	color: #003D79 !important;
}