.menu-main {
    width: 350px !important;
}

.menu-header {
    font-size: 18px !important;
    color: #898D8D !important;
    font-family: 'Muli', Arial, sans-serif !important;
    font-weight: bold !important;
    padding: 9px 0px 9px 17px !important;
}

.meanu-header-span {
    color: #003D79;
}

.superscript {
    position: relative !important;
    top: -1.1em !important;
    font-size: 0.4em !important;
}

.menu-panel {
    color: #60605b !important;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    font-size: 13px !important;
    width: 200px !important;
}

.menu-body {
    padding-left: 10px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.menu-bodyrequest {
    padding-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

i.request {
    width: 26px;
    height: 18px;
    display: inline-block;
    background: url("../../../src/img/HomePage2015/icon-nav-request.png") top left no-repeat;
    margin-right: 10px;
    margin-top: 2px;
    float: left;
}

i.document {
    width: 15px;
    height: 18px;
    display: inline-block;
    background: url("../../../src/img/HomePage2015/icon-nav-document.png") top left no-repeat;
    margin-right: 11px;
    margin-top: 2px;
    float: left;
}

i.gear {
    width: 21px;
    height: 21px;
    display: inline-block;
    background: url("../../../src/img/HomePage2015/icon-nav-gear.png") top left no-repeat;
    margin-right: 11px;
    margin-top: 2px;
    float: left;
}

.width100P {
    width: 100% !important;
}