$borderColor: #d1ccbf;

.cd-response-border {
	border: 1px solid $borderColor !important;
}

.cd-response .service-types-table {
	max-width: 70em;
}

.cd-response .nav {
	flex-wrap: nowrap;
}

.cd-response .response-tabs {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.cd-response .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: transparent;
}

.cd-response .nav-tabs .nav-item {
	margin-bottom: -1px;
	border: 1px solid #d8d8d8;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.cd-response li.active.nav-item {
	background-color: #f3f2ef !important;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	border-bottom: none;
}

.cd-response a.active.nav-link {
	background-color: #f3f2ef !important;
	border-bottom: none;
}

.el3ResponsePage,
table.patient-info-left {
	border: 1px solid $borderColor;
	height: 510px;
}

.el3ResponsePage,
table.patient-info-left thead th {
	background: #003D79;
	color: #fff;
	padding: 8px 12px;
	height: 20px;
}

.chkboxlbl {
	margin-top: 2px;
	width: 222px;
}

.el3ResponsePage,
table.patient-info-left td {
	border-bottom: 0;
	border-top: 0;
	/*padding:12px 12px; */
}

.el3ResponsePage,
table.patient-info-left td:first-child {
	border-right: 1px solid $borderColor;
	background: #f3f2ef;
	color: #003D79;
	width: 100px;
}

.el3ResponsePage,
#patient-tabs .tab-content table.patient-info-left thead th h3 {
	color: #fff;
	margin: 0;
	padding: 0;
	font-size: 18px;
}

.el3ResponsePage,
table.patient-info-left-pending-response {
	border: 1px solid $borderColor;
	height: 250px;
}

.el3ResponsePage,
table.patient-info-left-pending-response thead th {
	background: #003D79;
	color: #fff;
	padding: 1px 12px;
	height: 20px;
}

.el3ResponsePage,
table.patient-info-left-pending-response td {
	border-bottom: 0;
	border-top: 0;
	/*padding:12px 12px; */
}

.el3ResponsePage,
table.patient-info-left-pending-response td:first-child {
	border-right: 1px solid $borderColor;
	background: #f3f2ef;
	color: #003D79;
	width: 100px;
}

.el3ResponsePage,
#patient-tabs .tab-content table.patient-info-left-pending-response thead th h3 {
	color: #fff;
	margin: 0;
	padding: 0;
	font-size: 18px;
}

/*TFS-34810 CSS changes for newly added collapsible Service Coverage overview */

.el3ResponsePage,
.service-coverage {
	margin-bottom: 20px;
	clear: both;
}

.el3ResponsePage,
.accordion-group {
	border: 0;
	border-radius: 0;
	margin-bottom: 0;
}

.el3ResponsePage,
.service-coverage .accordion-heading .whiteText {
	color: #fff;
}

.el3ResponsePage,
.service-coverage .accordion-heading a {
	padding: 0;
	// background: url(../../img/icon-white-uparrow.png) 99% 50% no-repeat;
}

.el3ResponsePage,
.service-coverage .accordion-heading {
	color: #fff !important;
	font-size: 18px !important;
	margin: 0;
	padding: 8px 12px;
}

.EligibilityRequest,
.accordion-heading {
	background: #003D79;
	color: #fff;
	padding: 5px 15px;
	position: relative;
	display: block;
	border: 0;
}

.el3ResponsePage,
.accordion-body .service-types-table {
	margin: -20px 0 0 0;
}

.el3ResponsePage,
.service-types-table {
	width: 100%;
	margin: 20px 0 10px;
	table-layout: fixed;
	clear: both;
	border-collapse: collapse;
}

.el3ResponsePage,
.service-types-table tr.summaryDisplaying td:first-child {
	width: 50%;
	background: #fff;
	border-right: 0;
	color: #96938e;
}

.el3ResponsePage,
.service-types-table .summaryHideAll {
	margin: 0 0 0 15px;
}

.el3ResponsePage,
.service-types-table .summaryShowAll {
	margin: 0 0 0 15px;
}

.el3ResponsePage,
.service-types-table .summaryEdit {
	margin: 0 0 0 15px;
}

.el3ResponsePage,
.service-types-table tr.summarySection {
	border-bottom: 1px solid #dfe314;
}

.el3ResponsePage,
.service-types-table tr.summarySection td:last-child {
	border-right: 1px solid $borderColor;
	border-left: 0;
	background: #f2f5da;
}

.RequestHistory,
table tbody {
	border-radius: 0;
}

.RequestHistory,
table {
	width: 100%;
	padding: 0;
	margin-bottom: 15px;
	color: #555545;
	font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
	border-radius: 0;
	background: #fff;
}

.el3ResponsePage,
.service-types-table tr.summarySection td:first-child {
	background: #f2f5da;
	border-right: 0;
}

.el3ResponsePage,
.service-types-table tbody>tr>td:nth-child(2n + 1) {
	background-color: #f3f2ef;
	border-left: 1px solid $borderColor;
	border-right: 1px solid $borderColor;
	color: #003D79;
}

.el3ResponsePage,
.service-types-table tr.summarySection td {
	background: #f2f5da;
}

.el3ResponsePage,
.service-types-table tbody td:nth-child(2n + 1) {
	width: 170px;
}

.el3ResponsePage,
.service-types-table tr:last-child td {
	border-bottom: 1px solid $borderColor;
}

.el3ResponsePage,
.service-types-table tbody tr td {
	padding: 10px 20px;
	vertical-align: top;
	margin: 2px;
	border-top: 0;
}

.active {
	color: #003D79;
}

/*End*/
.cardHeading {
	background: #003D79;
	color: #fff;
	padding: 1px 12px;
	height: 40px;
}

.card {
	width: 100%;
	border: 1px solid #e5e6dd;
	border-radius: 10px 0;
	padding: 0px 0px;
	transition: all 0.3s ease-in-out;
	color: #898d8d;
}

.card-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 0rem;
}

.response-error {
	padding: 5px 35px 5px 14px;
	border-radius: 0;
	border: 1px solid #fbeed5;
	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
	background-color: #fcf8e3;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	behavior: url(/files/PIE.htc);
	border-color: #eed3d7;
	color: #b94a48;
	background-color: #f2dede;
	display: inline-block;
}